"use client";
import { createContext, PropsWithChildren, useState } from "react";
import { Locale, SITE } from "./types";
import { BrowserTracker } from "@snowplow/browser-tracker";

export const SiteContext = createContext({
  site: "hyre.no" as SITE,
  locale: "no" as Locale,
});

export const TrackerContext = createContext<{
  tracker: BrowserTracker | null;
  setTracker: (t: BrowserTracker) => void;
}>({
  tracker: null,
  setTracker: () => {},
});

export const TrackerProvider = ({ children }: PropsWithChildren) => {
  const [tracker, setTracker] = useState<BrowserTracker | null>(null);

  return (
    <TrackerContext.Provider value={{ tracker, setTracker }}>
      {children}
    </TrackerContext.Provider>
  );
};
